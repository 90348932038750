import classnames from "classnames";
import { SyntheticEvent } from "react";
import * as React from "react";
import { ButtonKind } from "./Button.component";
import "./FauxButton.style.scss";
import { MagicLink, MagicLinkProps } from "@inferno/renderer-shared-ui";

interface FauxButtonProps extends MagicLinkProps {
  className?: string;
  children?: React.ReactNode;
  kind?: ButtonKind | undefined;
  size?: "default" | "small";
  target?: string;
  click?: (e: SyntheticEvent) => void;
}

export const FauxButton = ({
  className,
  children,
  target = "_self",
  kind = ButtonKind.PRIMARY,
  size = "default",
  ref: _,
  ...magic
}: FauxButtonProps) => {
  const buttonClasses = classnames("component-button", "button-faux", {
    [`${kind}`]: kind,
    [`${size}`]: size !== "default",
    [`${className}`]: className,
  });
  return (
    <MagicLink {...magic} className={buttonClasses} target={target}>
      {children}
    </MagicLink>
  );
};

export default FauxButton;
